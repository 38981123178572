import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import { Link, Typography } from '@mui/material';
import { urlBuilder } from '@/utils/api';
import { SubPageTemplate } from '@/pages/SubPageTemplate';
import { FlexContainer } from '@/components/Layout/Container';
import { useTranslation } from '@/hooks/useTranslation';
import ErrorIcon from '@mui/icons-material/Error';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '../theme/theme';
import { useLazyGetVoucherPdfCheckQuery } from '@/api/order';

const useStyles = makeStyles((theme: Theme) => ({
    link: {
        color: theme.palette.error.main,
        fontSize: '3rem',
        marginBottom: theme.spacing(2),
    },
}));

export const VouchersDownloadPage = () => {
    const { id } = useParams<{ id: string }>();
    const [getVouchersPdfCheck] = useLazyGetVoucherPdfCheckQuery();
    const navigate = useNavigate();
    const { getPagePath, routerConfig } = useCustomRouter();
    const { getTranslated } = useTranslation();

    const classes = useStyles();

    const [isIdTested, setIsIdTested] = useState(false);
    const [isIdCorrect, setIsIdCorrect] = useState(false);

    useEffect(() => {
        if (!id) {
            navigate(getPagePath(routerConfig.Home));
        } else {
            // test id before showing the page
            // if the link is broken -> redirect to home page (or present a meaningful error message)
            getVouchersPdfCheck(id)
                .unwrap()
                .then(() => {
                    setIsIdCorrect(true);
                })
                .catch(() => {
                    setIsIdCorrect(false);
                })
                .finally(() => {
                    setIsIdTested(true);
                });
        }
    }, []);

    return (
        <SubPageTemplate>
            {isIdTested ? (
                <>
                    {isIdCorrect ? (
                        <FlexContainer flexGrow={1} center column>
                            <Typography variant={'h6'} gutterBottom>
                                {getTranslated('VoucherDownloadPage.headline')}
                            </Typography>

                            <Link
                                href={`${urlBuilder(
                                    'order',
                                    'vouchers'
                                )}/${id}.pdf`}
                                target='_blank'
                            >
                                {getTranslated('VoucherDownloadPage.download')}
                            </Link>
                        </FlexContainer>
                    ) : (
                        <FlexContainer flexGrow={1} center column>
                            <ErrorIcon className={classes.link} />
                            <Typography variant={'h6'} gutterBottom>
                                {getTranslated('Core.invalidLink')}
                            </Typography>

                            <Button
                                color='primary'
                                onClick={() => {
                                    navigate(getPagePath(routerConfig.Home));
                                }}
                            >
                                {getTranslated('Core.backToHome')}
                            </Button>
                        </FlexContainer>
                    )}
                </>
            ) : null}
        </SubPageTemplate>
    );
};
