import queryStrings from 'query-string';
import { urlBuilder } from '@/utils/api';
import { isProductFilterComplete, ProductFilter } from '@/models/productFilter';
import {
    flattenLiftOperatorByFacilities,
    LiftOperator,
} from '@/models/liftOperators';
import {
    BackendType,
    FrontendType,
    MultiLangString,
    ObjectType,
} from '@/models/general';
import { configSlice, PortalConfig } from '@/slices/configSlice';
import { useProductFilter } from '@/hooks/useProductFilter';
import { useConfigSlice } from '@/hooks/useConfigSlice';
import { buildProductCollectionsFromProducts } from '@/models/collection/productCollection';
import { Favorite } from '@/models/user';
import { ProductEnvironment } from '@/models/order';

import { useSlice } from '@/hooks/useSlice';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { liftOperatorSlice } from '@/slices/liftOperatorSlice';
import {
    convertProductsOverviewToFrontend,
    convertProductToFrontend,
    Product,
    ProductsOverview,
    ProductType,
} from '@/models/product';
import { baseApiAuthenticated } from '@/api/index';
import { Configuration } from '@/models/configuration';

const LIFT_OPERATOR_SERVICE = 'liftoperators';
const BASE_ENDPOINT = 'liftoperators';
const BASE_API = urlBuilder(LIFT_OPERATOR_SERVICE, `${BASE_ENDPOINT}`);

const liftOperatorUrls = (id?: string) => ({
    productsDetails: `${BASE_API}/${id}/products-details`,
    productsOverview: `${BASE_API}/${id}/products-overview`,
    favorites: urlBuilder(LIFT_OPERATOR_SERVICE, `favorites`),
    liftOperators: urlBuilder(LIFT_OPERATOR_SERVICE, `liftoperators`),
    oeamtcLiftOperators:
        'https://oeamtc.partner.starjack.com/rest/liftoperators',
});

type GetOeamtcLiftOperatorResponse = {
    liftoperators: {
        name: string;
        uuid: string;
        active: boolean;
        noOffersText: string;
        listLabel: string;
    }[];
};

type GetProductDetailsParams = {
    liftOperatorId: string;
    productFilter: Partial<ProductFilter>;
    configuration: PortalConfig;
    tags: string[];
};

type GetProductDetailsResponse<T extends ObjectType = FrontendType> = {
    filterTags: MultiLangString<string[]>;
    products: Product<T>[];
    liftOperatorId: string;
};

type GetProductsOverviewParams = {
    liftOperatorId: string;
    tags: string[];
    configuration: PortalConfig;
};

type GetProductsOverviewResponse<T extends ObjectType = FrontendType> =
    ProductsOverview<T>;

const liftOperatorsApi = baseApiAuthenticated
    .enhanceEndpoints({ addTagTypes: ['FAVORITES'] })
    .injectEndpoints({
        endpoints: (builder) => ({
            getLiftOperatorById: builder.query<
                LiftOperator,
                { liftOperatorId: string }
            >({
                query: ({ liftOperatorId }) =>
                    `${liftOperatorUrls().liftOperators}/${liftOperatorId}`,
            }),
            getProductDetails: builder.query<
                GetProductDetailsResponse,
                GetProductDetailsParams
            >({
                query: ({
                    liftOperatorId,
                    productFilter,
                    configuration,
                    tags,
                }) => {
                    const productFilterCopy = {
                        ...productFilter,
                        persons:
                            productFilter.persons
                                ?.map((person) => {
                                    const personName = person.name.de;

                                    return Array.from(
                                        { length: person.quantity },
                                        () => personName
                                    );
                                })
                                .flat()
                                .join(',') || null,
                    };

                    for (const key in productFilter) {
                        if (
                            productFilter[key] === null ||
                            productFilter[key] === undefined
                        ) {
                            delete productFilterCopy[key];
                        }
                    }

                    delete productFilterCopy.variant;

                    const { persons, type, date } = productFilterCopy;

                    const queryString = queryStrings.stringify(
                        {
                            persons,
                            type,
                            startDay:
                                type === ProductType.DAY
                                    ? date?.format('YYYY-MM-DD')
                                    : null,
                            tags: tags.join(','),
                        },
                        { skipNull: true }
                    );

                    return urlBuilder(
                        LIFT_OPERATOR_SERVICE,
                        `${BASE_ENDPOINT}/${liftOperatorId}/products-details?environment=${getEnvironment(
                            configuration
                        )}&${queryString}`
                    );
                },

                transformResponse: (
                    {
                        products,
                        liftOperatorId,
                        filterTags,
                    }: GetProductDetailsResponse<BackendType>,
                    _,
                    args
                ) => {
                    return {
                        products: products
                            .filter((p) => {
                                if (args.productFilter.variant === 'SINGLE') {
                                    return p.persons.length === 1;
                                }

                                return true;
                            })
                            .map(convertProductToFrontend),
                        filterTags,
                        liftOperatorId,
                    };
                },
            }),

            getProductOverview: builder.query<
                GetProductsOverviewResponse,
                GetProductsOverviewParams
            >({
                query: ({ tags, configuration, liftOperatorId }) => {
                    const searchParams = new URLSearchParams();

                    if (tags.length) {
                        searchParams.append('tags', tags.join(','));
                    }

                    return `${
                        liftOperatorUrls(liftOperatorId).productsOverview
                    }?environment=${getEnvironment(configuration)}${
                        searchParams.toString().length
                            ? `&${searchParams.toString()}`
                            : ''
                    }`;
                },
                transformResponse: (
                    response: GetProductsOverviewResponse<BackendType>
                ) => convertProductsOverviewToFrontend(response),
            }),

            getFavorites: builder.query<Favorite[], void>({
                query: () => liftOperatorUrls().favorites,
                transformResponse: (response: { favorites: Favorite[] }) => {
                    return response.favorites;
                },
                providesTags: ['FAVORITES'],
            }),

            deleteFavorite: builder.mutation<void, { liftOperatorId: string }>({
                query: ({ liftOperatorId }) => ({
                    method: 'DELETE',
                    url: `${liftOperatorUrls().favorites}/${liftOperatorId}`,
                }),
                invalidatesTags: ['FAVORITES'],

                onQueryStarted: async (
                    { liftOperatorId },
                    { dispatch, queryFulfilled }
                ) => {
                    const patchResult = dispatch(
                        liftOperatorsApi.util.updateQueryData(
                            'getFavorites',
                            undefined,
                            (draft) => {
                                return draft.filter(
                                    (favorite) =>
                                        favorite.liftOperatorId !==
                                        liftOperatorId
                                );
                            }
                        )
                    );
                    try {
                        await queryFulfilled;
                    } catch {
                        patchResult.undo();
                    }
                },
            }),

            postFavorite: builder.mutation<void, { liftOperatorId: string }>({
                query: ({ liftOperatorId }) => ({
                    method: 'POST',
                    url: `${liftOperatorUrls().favorites}/${liftOperatorId}`,
                }),
                invalidatesTags: ['FAVORITES'],

                onQueryStarted: async (
                    { liftOperatorId },
                    { dispatch, queryFulfilled }
                ) => {
                    const patchResult = dispatch(
                        liftOperatorsApi.util.updateQueryData(
                            'getFavorites',
                            undefined,
                            (draft) => [...draft, { liftOperatorId }]
                        )
                    );
                    try {
                        await queryFulfilled;
                    } catch {
                        patchResult.undo();
                    }
                },
            }),

            getStarjackLiftOperators: builder.query<
                LiftOperator[],
                { productEnvironment: ProductEnvironment }
            >({
                query: ({ productEnvironment }) => ({
                    method: 'GET',
                    url: `${
                        liftOperatorUrls().liftOperators
                    }?size=1000&environment=${productEnvironment}`,
                }),

                transformResponse: (baseQueryReturnValue: {
                    content: LiftOperator[];
                }): LiftOperator[] =>
                    baseQueryReturnValue.content
                        .map(flattenLiftOperatorByFacilities)
                        .flat(),
            }),

            getOeamtcLiftOperators: builder.query<
                GetOeamtcLiftOperatorResponse,
                void
            >({
                query: () => ({
                    method: 'GET',
                    url: `${liftOperatorUrls().oeamtcLiftOperators}`,
                }),
            }),

            getConfiguration: builder.query<
                Configuration,
                { vanityIdentifier: string }
            >({
                query: ({ vanityIdentifier }) => ({
                    url: `${urlBuilder(
                        LIFT_OPERATOR_SERVICE,
                        'configurations'
                    )}/${vanityIdentifier}`,
                    method: 'GET',
                }),
            }),
        }),
    });

const getEnvironment = (configuration: PortalConfig) =>
    configuration === PortalConfig.INTEGRATION_CONFIGURATION
        ? 'INTEGRATION'
        : 'PORTAL';

export const {
    useGetProductOverviewQuery,
    useGetProductDetailsQuery,
    useLazyGetProductDetailsQuery,
    useGetFavoritesQuery,
    usePostFavoriteMutation,
    useDeleteFavoriteMutation,
    useGetLiftOperatorByIdQuery,
    useLazyGetLiftOperatorByIdQuery,
    useLazyGetConfigurationQuery,
} = liftOperatorsApi;

export const useGetLiftOperatryWithOeamtcDataById = (queryParams: {
    liftOperatorId: string;
}) => {
    const {
        state: { isOeamtc },
    } = useSlice(configSlice, 'config');
    const { data: liftOperator, ...rest } =
        useGetLiftOperatorByIdQuery(queryParams);

    const { data: oeamtLiftOperatorResponse } =
        liftOperatorsApi.useGetOeamtcLiftOperatorsQuery(undefined, {
            skip: !isOeamtc,
        });

    if (
        !liftOperator ||
        oeamtLiftOperatorResponse?.liftoperators.length === 0
    ) {
        return { data: liftOperator, ...rest };
    }

    const oeamtcLiftOperator = oeamtLiftOperatorResponse?.liftoperators.find(
        (l) => l.uuid === liftOperator.id
    );

    if (!oeamtcLiftOperator) {
        return { data: liftOperator, ...rest };
    }

    // fill liftOperator with oeamtc data
    return {
        data: {
            ...liftOperator,
            noOffersText: oeamtcLiftOperator.noOffersText,
            listLabel: oeamtcLiftOperator.listLabel,
        },
        ...rest,
    };
};

export const useGetLiftOperatorsQuery = () => {
    const {
        state: { productEnvironment, isOeamtc },
    } = useSlice(configSlice, 'config');
    const { data: liftOperators = [], ...rtqkQueryData } =
        liftOperatorsApi.useGetStarjackLiftOperatorsQuery({
            productEnvironment,
        });
    const { data: oeamtcLiftOperators } =
        liftOperatorsApi.useGetOeamtcLiftOperatorsQuery(undefined, {
            skip: !isOeamtc,
        });

    const filtered = useMemo(() => {
        const activeLiftOperators = liftOperators.filter((l) => {
            if (isOeamtc) {
                return l.active;
            }

            // portal
            return l.active && !l.disablePortal;
        });

        if (!isOeamtc) {
            return activeLiftOperators;
        }

        return activeLiftOperators
            .map((l) => {
                const oeamtcL = oeamtcLiftOperators?.liftoperators.find(
                    (ol) => ol.uuid === l.id
                );

                if (oeamtcL) {
                    return {
                        ...l,
                        noOffersText: oeamtcL.noOffersText,
                        listLabel: oeamtcL.listLabel,
                    };
                }

                return null;
            })
            .filter((l) => l !== null);
    }, [liftOperators, oeamtcLiftOperators]);

    return { ...rtqkQueryData, data: filtered };
};

export const useGetProductDetailsQueryWrapper = () => {
    const [{ productFilter }] = useProductFilter();
    const {
        state: { selectedLiftOperator },
    } = useSlice(liftOperatorSlice, 'liftOperator');
    const { configuration } = useConfigSlice();
    const [query] = useSearchParams();

    return useGetProductDetailsQuery(
        {
            liftOperatorId: selectedLiftOperator.id,
            productFilter,
            configuration,
            tags: query.getAll('tags'),
        },
        {
            skip:
                !selectedLiftOperator.id ||
                !isProductFilterComplete(productFilter),
        }
    );
};

export const useGetProductCollectionsQuery = () => {
    const {
        state: { selectedLiftOperator },
    } = useSlice(liftOperatorSlice, 'liftOperator');
    const { data: productDetails, ...queryData } =
        useGetProductDetailsQueryWrapper();

    if (!productDetails) {
        return {
            productCollections: [],
            ...queryData,
        };
    }

    const products: Product[] = productDetails.products;

    return {
        productCollections: buildProductCollectionsFromProducts(
            products,
            selectedLiftOperator
        ).sort(
            (collectionA, collectionB) =>
                collectionB.sortKey - collectionA.sortKey
        ),
        ...queryData,
    };
};
