import { FunctionComponent, ReactNode } from 'react';
import { Typography } from '@mui/material';
import { FlexContainer } from '@/components/Layout/Container';
import { Money, MultiLangString } from '@/models/general';
import { getMoneyFormated } from '@/utils/general';
import { getPropByCurrentLocale } from '@/utils/locale';
import { styled } from '@mui/material/styles';

type TicketPriceLineProps = {
    title: string;
    subtitle: string;
    subtitle2?: MultiLangString[];
    subtitle3?: React.ReactNode;
    price: Money;
    originalPrice?: Money;
    priceSubtitle?: ReactNode;
};

export const CollectionCardPriceLine: FunctionComponent<
    TicketPriceLineProps
> = ({
    title,
    subtitle,
    subtitle2,
    subtitle3,
    price,
    originalPrice,
    priceSubtitle,
}) => {
    const hasDiscount = !!originalPrice && price.amount < originalPrice.amount;

    return (
        <FlexContainer
            sx={{
                width: '100%',
                flexWrap: 'wrap',
                alignItems: 'center',
                mb: !!subtitle ? 3 : 0,
            }}
        >
            <FlexContainer
                sx={() => ({
                    width: '70%',
                    pl: 3,
                    flexDirection: 'column',
                })}
            >
                <Typography
                    sx={{
                        fontWeight: 600,
                    }}
                    gutterBottom={!!subtitle}
                >
                    {title}
                </Typography>

                {!!subtitle && <Typography>{subtitle}</Typography>}
            </FlexContainer>

            <FlexContainer
                sx={{
                    ml: 'auto',
                    flexDirection: 'column',
                }}
            >
                <FlexContainer
                    column
                    center
                    sx={{
                        position: 'relative',
                        ml: 'auto',
                    }}
                >
                    {!!hasDiscount && <StrikeThroughLine />}

                    <Typography component='span'>
                        EUR&nbsp;
                        {getMoneyFormated(hasDiscount ? originalPrice : price)}
                    </Typography>
                    {!hasDiscount && priceSubtitle}
                </FlexContainer>

                {!!hasDiscount && (
                    <>
                        <Typography
                            sx={(theme) => ({
                                color: theme.palette.secondary.main,
                                position: 'relative',
                                ml: 'auto',
                            })}
                        >
                            <>EUR&nbsp;{getMoneyFormated(price)}</>
                        </Typography>
                        {priceSubtitle}
                    </>
                )}
            </FlexContainer>

            <FlexContainer column fullWidth>
                {!!subtitle2?.length && (
                    <FlexContainer
                        sx={(theme) => ({
                            alignItems: 'center',
                            mt: 1,
                            [theme.breakpoints.up('lg')]: {
                                pl: 3,
                            },
                        })}
                    >
                        <Typography
                            sx={(theme) => ({
                                ml: 1,
                                color: theme.palette.secondary.main,
                                fontSize: '0.85rem',

                                [theme.breakpoints.down('lg')]: {
                                    ml: 3,
                                },
                            })}
                        >
                            {getPropByCurrentLocale(subtitle2[0])}
                        </Typography>
                    </FlexContainer>
                )}

                {!!subtitle3 && (
                    <FlexContainer
                        sx={(theme) => ({
                            alignItems: 'center',
                            mt: 1,
                            [theme.breakpoints.up('lg')]: {
                                pl: 3,
                            },
                        })}
                    >
                        {subtitle3}
                    </FlexContainer>
                )}
            </FlexContainer>
        </FlexContainer>
    );
};

const StrikeThroughLine = styled('span')(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    right: 0,
    left: 0,
    transform: 'translateY(-50%) rotateZ(345deg)',
    backgroundColor: theme.palette.secondary.main,
    height: 2,
    width: '100%',
}));
